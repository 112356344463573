import React from 'react';

import Page from './Page';
import DuvidasFrequentes from './../DuvidasFrequentes';

import Card from './../Card';
import PageSubtitle from './../PageSubtitle';

import LogoBibliVirt from './../../imgs/logoMBiblioteca.png';
import LogoPergamun from './../../imgs/logoPerg.png';
import LogoPeriodicos from './../../imgs/logoPeriodicos.png';

function Biblioteca (){
    return (
        <Page title="Biblioteca">
            <PageSubtitle title="Acessos" />
            <div className="row row-eq-height mb-4">
                <div className="col-12 col-lg-4 mb-3">
                    <Card 
                        img={LogoBibliVirt}
                        description="Com a Minha Biblioteca você tem acesso a diversos livros e materiais disponiveis a qualquer momento"
                    >
                        <p className="text-muted text-left mt-2">
                            <b>Para acessar:</b><br />
                            1) Acesse o Portal do Aluno<br />
                            2) Menu "Acadêmico" (lateral esquerda)<br />
                            3) Item "URL's Externas"<br />
                            4) Clique no link "Acesso a Biblioteca Digital"
                        </p>
                    </Card>
                </div>
                <div className="col-12 col-lg-4 mb-3">
                    <Card 
                        img={LogoPergamun}
                        description="Através do Portal Pergamum você pode realizar consultas ao acervo de livros e outros materiais disponíveis na biblioteca da Instituição, além da possibilitade de renovar os empréstimos que você possui com a biblioteca"
                        linkAction="http://www.pergamum.fw.uri.br"
                    />
                </div>
                <div className="col-12 col-lg-4 mb-3">
                    <Card
                        img={LogoPeriodicos}
                        description="Consulte periódicos, normas técnicas, entre vários outros materiais disponíveis"
                        linkAction="http://www.periodicos.capes.gov.br/"
                    />
                </div>
            </div>
            <DuvidasFrequentes />
        </Page>
    )
}

export default Biblioteca;