import React from 'react';

import Page from './Page';
import PageSubtitle from './../PageSubtitle';

import DuvidasFrequentes from './../DuvidasFrequentes';

const Informacoes = () => {

    return (
        <Page title="Informações">
            <PageSubtitle title="Usuários" />
            <div className="row row-eq-height mb-4">
                <div className="col-12 col-lg-4 mb-3">
                    <div className="card card-body shadow-sm">
                        <h5>Portal do Aluno</h5>
                        <p className="text-muted">Para acessar o Portal do Aluno e derivados você deve usar as seguintes informações:</p>                        
                        <p className="text-muted"><b>Usuário:</b> RA (Registro Acadêmico)</p>
                        <p className="text-muted"><b>Senha:</b> **Senha</p>
                        <hr className="w-25 mt-0 mx-auto" />
                        <p className="text-muted small mb-1">* E-mail pessoal cadastrado no momento da matricula</p>
                        <p className="text-muted small mb-0">** Se for o primeiro acesso, a senha foi enviada para o seu e-mail pessoal</p>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mb-3">
                    <div className="card card-body shadow-sm">
                        <h5>Computadores e Wi-fi</h5>
                        <p className="text-muted">Para acessar você deve usar as seguintes informações:</p>                        
                        <p className="text-muted"><b>Usuário:</b> *a000000</p>
                        <p className="text-muted"><b>Senha:</b> **Senha</p>
                        <hr className="w-25 mt-0 mx-auto" />
                        <p className="text-muted small mb-1">* O "000000" deve ser substituido pelo seu RA (Registro Acadêmico)</p>
                        <p className="text-muted small mb-0">** Se for o primeiro acesso, a senha foi enviada para o seu seu e-mail pessoal</p>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mb-3">
                    <div className="card card-body shadow-sm">
                        <h5>E-mail Institucional</h5>
                        <p className="text-muted">Para acessar você deve usar as seguintes informações:</p>                        
                        <p className="text-muted"><b>Usuário:</b> *a000000@uri.edu.br</p>
                        <p className="text-muted"><b>Senha:</b> **Senha</p>
                        <hr className="w-25 mt-0 mx-auto" />
                        <p className="text-muted small mb-1">* O "000000" deve ser substituido pelo seu RA (Registro Acadêmico)</p>
                        <p className="text-muted small mb-0">** Se for o primeiro acesso, a senha foi enviada para o seu e-mail pessoal</p>
                    </div>
                </div>
            </div>
            <DuvidasFrequentes />
        </Page>
    );
}

export default Informacoes;
