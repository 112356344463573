import React from 'react';
import styled from 'styled-components';

const Title = styled.h5`
    span {
        border-bottom: 2px solid #333;
        padding-bottom: 5px !important;
    }
`;

const PageSubtitle = ({ title }) => {
    return (
        <Title className="mb-4">
            <span>{title}</span>
        </Title>
    );
}

export default PageSubtitle;
