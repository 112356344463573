import React, { useContext } from 'react';
import { createGlobalStyle } from 'styled-components';
/*import { Route, Switch, Redirect, __RouterContext } from 'react-router-dom'; - Removido */
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'; /* - Adicionado */
import { useTransition, animated } from 'react-spring';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';

import MenuTopo from './components/Menus/MenuTopo';

import Educacao from './components/pages/Educacao';
import Biblioteca from './components/pages/Biblioteca';
import Informacoes from './components/pages/Informacoes';

const GlobalStyle = createGlobalStyle`
	html, body {
		background: #ffffff;
		color: #000000;
	}

	main {
		margin-top: 64px;
		padding-bottom: 40px;
	}

	.row-eq-height > [class^=col] {
		display: flex;
		flex-direction: column;
	}

	.row-eq-height .card {
		height: 100% !important;
	}

	.card {
		border-radius: 0 !important;
	}
`;

const App = () => {

	/*const { location } = useContext(__RouterContext); - Removido*/
	const location = useLocation(); /* - Adicionado */
	const transactions = useTransition(location, location => location.pathname, {
		from: {opacity: 0, transform: "translate(100%, 0)"},
		enter: {opacity: 1, transform: "translate(0%, 0)"},
		leave: {opacity: 0, transform: "translate(-50%, 0)"}
	});

	const menuItens = [
		{
			index: 1,
			to: '/explore',
			name: 'Educacional',
			component: Educacao,
			exact: true
		},
		{
			index: 2,
			to: '/explore/biblioteca',
			name: 'Biblioteca',
			component: Biblioteca
		},
		{
			index: 3,
			to: '/explore/informacoes',
			name: 'Informações',
			component: Informacoes
		},
	];

	return (
			
		<div className="App">
			<GlobalStyle />
			<MenuTopo menuItens={menuItens} />
			<main>
				<div className="container">
					{transactions.map(({item, props, key}) => (
						<animated.div key={key} style={props}>
							<Switch location={item}>
								{
									menuItens.map((item) => (
										<Route key={item.index} exact={(item.exact)} path={item.to} component={item.component} />
									))
								}
								<Redirect to="/" />
							</Switch>
						</animated.div>
					))}
				</div>
			</main>	
		</div>
	)
}

export default App;
