import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import duvidasFrequentes from './listDuvidasFrequentes';
import Pergunta from './Pergunta';
import PageSubtitle from './PageSubtitle'

const GlobalStyle = createGlobalStyle`
    #accordionContainer {
        .card {
            &:not(:first-child){
                border-top: 0 !important;
            }

            & .card-body > :last-child {
                margin-bottom: 0 !important;
            }
        }

    }
`;

const Input = styled.input`
    display: block;
    padding: 10px 20px;
    font-size: 13pt;
    color: #666666;
    background: transparent;
    border: 1px solid #333333;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 20px;

    :focus, :active, :visited {
        border-color: #333333 !important;
    }

    ::placeholder {
        color: #666666;
    }
    ::-webkit-input-placeholder { /* Edge */
        color: #666666;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #666666;
    }
`;

const DuvidasFrequentes = () => {

    const [search, setSearch] = useState("");

    const perguntas = duvidasFrequentes
        .filter(perg => {
            return (
                search === '' || 
                perg.pergunta.toLowerCase().includes(search.toLowerCase()) ||
                perg.tags.includes(search.toLowerCase())
            )
        })

    return (
        <div>
            <GlobalStyle />
            <PageSubtitle title="Dúvidas Frequentes" />
            <Input type="text" placeholder="Buscar..." onChange={event => setSearch(event.target.value)} value={search} />
            <div id="accordionContainer" className="mb-4">
                {
                    (perguntas.length) ? (
                        perguntas.map((item, key) => (
                            <Pergunta item={item} id={key} key={key} />
                        ))
                    ) : (
                        <p className="text-center text-muted small">Nenhuma resposta encontrada..</p>
                    )
                }
            </div>
        </div>
    );
}

export default DuvidasFrequentes;
