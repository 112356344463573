import React from 'react';
import styled, { keyframes, createGlobalStyle } from 'styled-components';

import Rodape from './../Rodape';

const Pages = styled.div`
    position: absolute;
    width: 100%;
    margin-top: 20px;
`;

const fadeIn = keyframes`
    from { opacity: 0; }
    to   { opacity: 1; }
`;

const Content = styled.div`    
    -webkit-animation: ${fadeIn} 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: ${fadeIn} 2s; /* Firefox < 16 */
    -ms-animation: ${fadeIn} 2s; /* Internet Explorer */
        -o-animation: ${fadeIn} 2s; /* Opera < 12.1 */
        animation: ${fadeIn} 2s;
`;

const GlobalStyle = createGlobalStyle``;

const Page = ({title, children}) => {

    return (
        <>
            <Pages>
                <GlobalStyle />
                <h4 className="mb-3">{title}</h4>
                <Content className="content">
                    {children}
                </Content>
                <Rodape />
            </Pages>
        </>
    );
};

export default Page;
