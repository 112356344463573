import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CardContent = styled.div`
    color: #000;
`;

const CardBody = styled.div``;

const CardImg = styled.div``;
const CardIcon = styled.h2``;

const CardDescription = styled.div`
    color: #616161;
`;

const CardFooter = styled.div`
    background: #ffffff !important;
    border: 0;
`;

const Card = ({ img, icon, title, description, linkAction, children }) => {
    return (
        <CardContent className="card mb-3 shadow-sm">
            <CardBody className="card-body text-center">
                {(img) ? (
                    <CardImg>
                        <img className="mb-2" alt="imagem" src={img} height="50" />
                    </CardImg>
                ) : ''}
                {(icon) ? (
                    <CardIcon>
                        <FontAwesomeIcon icon={icon} />
                    </CardIcon>
                ) : ''}
                {(title) ? <h5 className="card-title">{title}</h5> : <div className="mb-3" />}
                {(description) ? (
                    <CardDescription className="card-text">
                        {description}
                    </CardDescription>
                ): ''}
                {children}
            </CardBody>
            {(linkAction) ? (
                <CardFooter className="card-footer px-4 p3-2">
                    <a href={linkAction} target="_blanck" className="btn btn-primary btn-block">Acessar</a>
                </CardFooter>
            ) : ''}
        </CardContent>
    );
}

export default Card;
