const size = {
    small: '576px',
    medium: '768px',
    large: '992px',
    extra: '1200px'
}

export const device = {
    minSmall: `(min-width: ${size.small})`,
    maxSmall: `(max-width: ${size.small})`,
    minMedium: `(min-width: ${size.medium})`,
    maxMedium: `(max-width: ${size.medium})`,
    minLarge: `(min-width: ${size.large})`,
    maxLarge: `(max-width: ${size.large})`,
    minExtra: `(min-width: ${size.extra})`,
    maxExtra: `(max-width: ${size.extra})`,
};