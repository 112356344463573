import React from 'react';
import styled from 'styled-components';

import Page from './Page';
import Card from '../Card';
import PageSubtitle from '../PageSubtitle';

import DuvidasFrequentes from './../DuvidasFrequentes';

import LogoRMPortal from './../../imgs/logoRMPortal.png';
import LogoEducaMobile from './../../imgs/logoEducaMobile.png';
import LogoAprendaMais from './../../imgs/logoAprendaMais.jpg';
import LogoGmail from './../../imgs/gmail.png';
import LogoGoogleClassroom from './../../imgs/googleClassroom.png';
import LogoMeet from './../../imgs/googleMeet.png';

import BannerGoogle from './../../imgs/badgeGooglePlay.png';
import BannerApple from './../../imgs/badge-lrg.svg';

import BannerNovoApp from './../../imgs/appURI_1200x250.png';
import BannerNovoAppMobile from './../../imgs/appURI_mobile.png';

const Novo = styled.span`
    position: absolute;
    top: -4px;
    left: -4px;
    padding: 5px;
    background: red;
    color: white;
    border-radius: 5px;
`;

function Educacao () {
    return (
        <Page title="Explore">
            <PageSubtitle title="Acessos" />
            <div className="row row-eq-height mb-5">
                
                <div className="col-12 col-lg-4 mb-3">
                    <Card 
                        img={LogoRMPortal}
                        title="Portais URI" 
                        description="Portais URI"
                        linkAction="http://portais.uri.br/"
                    >
                    </Card>                       
                </div>

                <div className="col-12 col-lg-4 mb-3">
                    <Card 
                        img={LogoRMPortal}
                        title="Portal do Aluno" 
                        description="Portal de acesso para alunos"
                        linkAction="http://portal.uri.br/FrameHTML/web/app/edu/portaleducacional/login/"
                    >
                    </Card>
                </div>
                <div className="col-12 col-lg-4 mb-3">
                    <Card 
                        img={LogoRMPortal}
                        title="Portal do Professor" 
                        description="Portal de acesso para professores"
                        linkAction="https://portal.uri.br/FrameHTML/Web/App/Edu/PortalDoProfessor/#/login"
                    >                    
                    </Card>
                </div>
                <div className="col-12 col-lg-4 mb-3">
                    <Card 
                        img={LogoRMPortal}
                        title="RM Portal" 
                        description="Portal de acesso para colaboradores, professores e alunos"
                        linkAction="http://portal.uri.br/corpore.net/Login.aspx"
                    />
                </div>


                <div className="col-12 col-lg-4 mb-3">
                    <Card 
                        img={LogoGmail}
                        title="E-mail Institucional" 
                        description="Acesse o seu e-mail institucional do Google"
                        linkAction="https://accounts.google.com/ServiceLogin?service=mail&continue=https://mail.google.com/mail/"
                    />
                </div>
                
                <div className="col-12 col-lg-4 mb-3">
                    <Card 
                        img={LogoAprendaMais}
                        title="Aprenda Mais" 
                        description="Ambiente Virtual de Aprendizagem"
                        linkAction="https://aprendamais.uri.br"
                    />
                </div>

                <div className="col-12 col-lg-4 mb-3">
                    <Card 
                        img={LogoGoogleClassroom}
                        title="Google Classroom" 
                        description="Acesse sua sala de aula virtual do Google"
                        linkAction="https://classroom.google.com/"
                    />
                </div>
                <div className="col-12 col-lg-4 mb-3">
                    <Card 
                        img={LogoMeet}
                        title="Google Meet" 
                        description="Acesse o Google Meet para videoconferências"
                        linkAction="https://meet.google.com/?utm_campaign=DonanimHaber&utm_medium=referral&utm_source=DonanimHaber"
                    />
                </div>
            </div>
            <PageSubtitle title="Aplicativo" />
            <div className="row row-eq-height mb-5">
                <div className="col-12 col-lg-4 mb-3">
                    <div className="card card-body shadow-sm">
                        <div className="card-content text-center">
                            <h6 className="text-muted">Download</h6>
                            <a href="https://play.google.com/store/apps/details?id=com.itvix.uriapp" target="_blanck">
                                <img alt="Download Play Store" src={BannerGoogle} height="60" />
                            </a>
                            <p className="text-muted small my-2">ou</p>
                            <a href="https://apps.apple.com/us/app/meu-educonnect/id1509465101" target="_blanck">
                                <img alt="Download App Store" src={BannerApple} height="60" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-8 mb-3">
                    <div className="card card-body shadow-sm text-center d-flex justify-content-center align-items-center">
                        <img src={BannerNovoApp} className="img-fluid d-none d-lg-block" alt="Banner novo aplicativo da URI" />
                        <img src={BannerNovoAppMobile} className="img-fluid d-block d-lg-none" alt="Banner novo aplicativo da URI" />
                    </div>
                </div>
            </div>
            <DuvidasFrequentes />
        </Page>
    )
}

export default Educacao;