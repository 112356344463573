import React from 'react';

import diario_classe_portal from '../arquivos/diario_classe_portal.pdf';
import diario_classe_portal_professor from '../arquivos/diario_classe_portal_professor.pdf';
import tutorial_atividades_complementares_aluno from '../arquivos/tutorial_atividades_complementares_aluno.pdf';

const duvidasFrequentes = [
    {
        tags: ["senha", "portal", "acesso", "acessar", "esqueci"],
        pergunta: "Tutoriais",
        descricao: (
            <>
                <ul>
                    <li><a target="_blanck" href={diario_classe_portal}>Portal RM - alunos, frequência, notas e planos de aula </a></li>
                    <li><a target="_blanck" href={diario_classe_portal_professor}>Portal Professor - alunos, frequência, notas e planos de aula </a></li>
                    <li><a target="_blanck" href={tutorial_atividades_complementares_aluno}>Portal Aluno - solicitação de inclusão de atividade complementar</a></li>
                </ul>
            </>
        )
    },
    {
        tags: ["senha", "portal", "acesso", "acessar", "esqueci"],
        pergunta: "Não sei minha senha do Portal, onde a encontro?",
        descricao: (
            <>
                <p>No momento da sua matricula na Universidade, foi enviado para o seu e-mail pessoal uma senha temporária de acesso. Caso não tenha recebido este e-mail ou não cosegue acessar mesmo assim, você pode realizar dois procedimentos: <br /></p>
                <ul>
                    <li>Utilizar o procedimento "Esqueci minha senha" do <a target="_blanck" href="http://portal.uri.br">Portal</a>, informando seu usuário e e-mail pessoal;</li>
                    <li>Ou ir até o Setor de Atendimento da Universidade para realizar a troca de senha.</li>
                </ul>
            </>
        )
    },
    {
        tags: ["acesso", "acessar", "portal"],
        pergunta: "Não consigo acessar o meu Portal, o que fazer?",
        descricao: "Verifique se está digitando o seu usuário e senha corretamente. Caso seja necessário que informe os caracteres especiais, verificar se os mesmos estão corretos.",
    },
    {
        tags: ["acesso", "acessar", "portal", "não", "consigo", "erro", "travado", "travou", "pop-ups", "popups"],
        pergunta: "Consegui acessar o Portal, porém não consigo clicar nos itens e o portal parece estar travado. Como proceder?",
        descricao: (
            <>
                <p>
                    Verifique se os "pop-ups" do navegador (<a href="https://support.google.com/chrome/answer/95472?co=GENIE.Platform%3DDesktop&hl=pt-BR" target="_blanck">Google Chrome</a>, <a href="https://support.mozilla.org/pt-BR/kb/bloqueador-de-pop-up-excecoes-e-solucoes-de-problemas" target="_blanck">Mozilla Firefox</a>) estão habilitados para o Portal, recarregue o portal (tecla f5) e realize os passos de configuração.<br />
                    Caso ainda esteja com dúvidas você pode consultar o passo a passo para a conexão e configuração do seu Portal, clicando no link abaixo:<br/><br/>
                    <a href="https://urinet.fw.uri.br/temp/dir/Passo_a_passo_alunos_RM_Portal.pdf" target="_blanck">Passo a Passo de Acesso e Configuração do Portal</a>
                </p>
            </>
        ),
    },
    {
        tags: ["acesso", "acessar", "email", "e-mail", "institucional", "senha"],
        pergunta: "Não consigo acessar o meu E-mail Institucional, o que fazer?",
        descricao: (
            <>
                <p>
                    Você realiza o acesso do seu E-mail Institucional através do <a href="https://accounts.google.com/ServiceLogin?service=mail&continue=https://mail.google.com/mail/" target="_blanck">Google Gmail</a>.<br />
                    Para acessar você deve utilizar os seguintes dados:<br/>
                    <b>Usuário: </b>a000000@uri.edu.br (No lugar de "000000" substitua pelo seu RA (Registro Acadêmico))<br/>
                    <b>Senha: </b>Somente os números do seu CPF (No caso de primeiro acesso)<br />
                    <br />
                    Caso não consiga acessar com o seu CPF ou esqueceu a senha, você pode altera-la através do Portal, seguindo os passos: <br />
                </p>
                <ul>
                    <li>Acesse o Portal</li>
                    <li>Módulo Educacional</li>
                    <li>Menu Acadêmico (localizado no menu lateral esquerdo)</li>
                    <li>Item URL's Externas</li>
                    <li>Clique em "Portal de Serviços NTI"</li>
                    <li>Item "Computadores, E-mail e Wi-Fi"</li>
                    <li>Procure na lista o seu usuário "a000000" e altere a senha</li>
                </ul>
            </>
        ),
    },
    {
        tags: ["acesso", "acessar", "wi-fi", "wifi", "instituição", "senha"],
        pergunta: "Não consigo acessar o Wi-Fi da Instituição, o que eu faço?",
        descricao: (
            <>
                <p>
                    Para acessar o Wi-Fi da Instituição, sida os seguintes passos:
                </p>
                <ul>
                    <li>Através do dispositivo móvel, conecte-se à rede "URI-ALUNOS"</li>
                    <li>Utilize como usuário: a000000 (Substitua "000000" pelo seu RA (Registro Acadêmico))</li>
                    <li>Informa a senha (para primeiro acesso é o CPF)</li>
                </ul>
                <p>
                    Caso já tenha definido uma senha para o seu E-mail Institucional, esta será a mesma para acesso do Wi-Fi.<br/>
                    Caso após conectar a rede "URI-ALUNOS" não for questionado sobre usuário e senha, você deve utilizar algum navegador de internet (Google Chrome, Mozilla Firefox, Safari, ...) e realizar alguma pesquisa na internet, assim será forçado a solicitação das informações de acesso.
                </p>
            </>
        )
    },
    {
        tags: ["computadores", "laboratorios", "laboratórios", "senha"],
        pergunta: "Como posso fazer a utilização de computadores dos laboratórios da Instituição?",
        descricao: (
            <>
                <p>
                    Para realizar o acesso à computadores dos laboratórios você deve utilizar as seguintes informações:<br />
                    <b>Usuário:</b> a000000 (Substitua "000000" pelo seu RA (Registro Acadêmico))<br />
                    <b>Senha:</b> a mesma senha de acesso ao WI-FI e ao E-mail Institucional
                </p>
            </>
        )
    },
    {
        tags: ["acesso", "acessar", "Google", "Classroom"],
        pergunta: "Não sei acessar meu Google Classroom, como proceder?",
        descricao: (
            <>
                <p>Para acessar o Google Classroom, você pode fazer de duas maneiras:<br /></p>
                <ul>
                    <li>Acessar diretamente através do link <a href="https://classroom.google.com/" target="_blanck">https://classroom.google.com/</a> e usar seus dados do e-mail institucional;</li>
                    <li>Ou acessar o seu E-mail Institucional e, através do menu "Aplicativos" clicar no item referente ao Google Classroom</li>
                </ul>
            </>
        ),
    },
    {
        tags: ["visualizar", "turmas", "Google", "Classroom"],
        pergunta: "Não consigo visualizar minhas turmas no Google Classroom, o que devo fazer?",
        descricao: (
            <>
                <p>
                    Caso não consiga visualizar as suas turmas, <b>solicite ao professor da disciplina</b> que lhe inclua na turma ou que lhe informe o código da turma. Caso o professor lhe informe o código da turma, você deve, através do Google Classroom, acessar o menu "+" (canto superior direito), clicar em "Participar da turma" e informar o código da turma.
                </p>
            </>
        ),
    },
    {
        tags: ["acesso", "acessar", "Google", "Meet"],
        pergunta: "Como faço para acessar o Google Meet?",
        descricao: (
            <>
                <p>Existem duas formas de participar de reuniões através do Google Meet:</p>
                <ul>
                    <li>Realizando o login no seu E-mail Institucional, clicar no item "Google Meet" (no meu Aplicativos) e informar o código da reunião;</li>
                    <li>ou receber um link de acesso à uma reunião.</li>
                </ul>
                <p>Caso você receba o link para a reunião, após carregar a página no canto superior direito será habilitado o item "Fazer login", clique nele e informa os dados do seu E-mail Institucional</p>
            </>
        ),
    },
    {
        tags: ["biblioteca", "digital", "acesso", "acessar"],
        pergunta: "Como posso acessar a Biblioteca Digital?",
        descricao: (
            <>
                <p>Para acessar a Biblioteca Digital siga os passos:</p>
                <ul>
                    <li>Acesse o seu Portal de Aluno</li>
                    <li>Menu "Acadêmico" (lateral esquerda)</li>
                    <li>Item "URL's Externas"</li>
                    <li>Clique no link "Acesso Biblioteca Digital"</li>
                </ul>
            </>
        ),
    },
];

/*
{
    tags: [],
    pergunta: "",
    descricao: ""
}
*/

export default duvidasFrequentes;