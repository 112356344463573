import React from 'react';

const Rodape = () => {
    return (
        <footer className="footer mt-auto py-3 text-center">
            <div className="container">
                <span className="text-muted">&copy; NTI URI Frederico Westphalen</span>
            </div>
        </footer>
    );
}

export default Rodape;
