import React from 'react';

const Pergunta = ({ item, id }) => {    
    return (
        <div className="card">
            <div className="card-header py-2" id={"heading"+id}>
                <h2 className="mb-0">
                    <button className="btn btn-link text-decoration-none py-0" type="button" data-toggle="collapse" data-target={"#collapse"+id} aria-expanded="true" aria-controls={"collapse"+id}>
                        {item.pergunta}
                    </button>
                </h2>
            </div>

            <div id={"collapse"+id} className="collapse" aria-labelledby={"heading"+id} data-parent="#accordionContainer">
                <div className="card-body text-dark">
                    { item.descricao }
                </div>
            </div>
        </div>
    );
}

export default Pergunta;
