import React, { Component } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import {NavLink} from 'react-router-dom';

import { device } from './../../styles/devices';

import LogoMobile from '../../imgs/logotopo_mobile.png';
import LogoDesktop from '../../imgs/logotopo_desktop_red.png';

const NavBarTopo = styled.nav`
    background: #006699;
    

    @media ${device.minLarge} { 
        padding-top: 0;
        padding-bottom: 0;
    }
`;

const Li = styled.li`
    a.active {
        background-color: rgba(0, 0, 0, 0.1) !important;
    }
`;

const ButtonHangurguer = styled.button`
    color: #ffffff !important;
    width: 50px;
    border: 0;
`;

export default class MenuTopo extends Component {

    render() {

        return (
            <div>
                <NavBarTopo className="navbar navbar-expand-lg navbar-dark fixed-top navbar-topo">
                    <div className="container">
                        <div className="navbar-brand">
                            <img alt="URI" className="d-inline d-lg-none" src={LogoMobile} />
                            <img alt="URI" className="d-none d-lg-inline" src={LogoDesktop} height="35" />
                        </div>
                        <ButtonHangurguer className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTopoContent" aria-controls="navbarTopoContent" aria-expanded="false" aria-label="Toggle navigation">
                            <FontAwesomeIcon icon={faBars} />
                        </ButtonHangurguer>

                        <div className="collapse navbar-collapse" id="navbarTopoContent">
                            <ul className="navbar-nav ml-auto">
                                {
                                    this.props.menuItens.map(item => {
                                        return (
                                            <Li key={item.index}
                                                data-toggle="collapse" data-target="#navbarTopoContent.show"
                                                className="nav-item" 
                                            >
                                                <NavLink exact={(item.exact)} strict className="nav-link p-2 p-lg-3" to={item.to}>
                                                    {item.name}
                                                </NavLink>
                                            </Li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </NavBarTopo>
            </div>
        );
    }
}
